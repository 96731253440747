<template>
  <div
    class="card card-custom grid-stack-item-content"
    style="
      cursor: move;
      background-position: right top;
      background-size: 30% auto;
      background-image: url('/media/svg/shapes/abstract-2.svg');
    "
  >
    <div class="card-body">
      <div class="d-flex justify-content-between flex-column pt-2 h-50">
        <div class="pb-1">
          <div class="d-flex flex-column flex-center">
            <div class="symbol symbol-100">
              <img
                :src="contactPerson.profile_picture ?? ''"
                alt=""
                class="symbol-label"
                @error="imageError = true"
              />
              <i class="symbol-badge bg-success"></i>
            </div>
            <div
              class="card-title font-weight-bolder text-dark-75 font-size-h4 m-0 pt-7 pb-1"
            >
              {{
                contactPerson.name
                  ? contactPerson.name
                  : $t("general.noContactPerson")
              }}
            </div>
            <div class="font-weight-bold text-dark-50 font-size-sm pb-6">
              {{ $t("general.yourContactPerson") }}
            </div>
          </div>
          <div class="pt-1">
            <div
              v-if="contactPerson.phone"
              class="d-flex align-items-center pb-9"
            >
              <div class="symbol symbol-45 symbol-light mr-4">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-dark-50">
                    <i class="fal fa-phone" style="font-size: 24px"></i>
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <div class="text-dark-75 mb-1 font-size-lg font-weight-bolder">
                  {{ $t("general.phone") }}
                </div>
                <span class="text-muted font-weight-bold">{{
                  contactPerson.phone
                }}</span>
              </div>
            </div>
            <div
              v-if="contactPerson.email"
              class="d-flex align-items-center pb-9"
            >
              <div class="symbol symbol-45 symbol-light mr-4">
                <span class="symbol-label">
                  <span class="svg-icon svg-icon-2x svg-icon-dark-50">
                    <i class="fal fa-at" style="font-size: 24px"></i>
                  </span>
                </span>
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <div class="text-dark-75 mb-1 font-size-lg font-weight-bolder">
                  {{ $t("general.email") }}
                </div>
                <span class="text-muted font-weight-bold">{{
                  contactPerson.email
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <a
          v-if="contactPerson.email"
          :href="'mailto:' + contactPerson.email"
          class="btn btn-primary"
        >
          {{ $t("general.sendMessage") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Users from "@/components/Tenants/Users/users";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";

export default {
  name: "GeneralDashboardAppsClientsContactPerson",
  data() {
    return {
      imageError: false,
      contactPerson: {
        profile_picture: "",
        name: "",
        phone: "",
        email: ""
      },
      clientSelected: false
    };
  },
  computed: {
    ...mapGetters(["selectedPartner", "selectedClient"])
  },
  watch: {
    selectedPartner: function () {
      // this.loadMainContactPerson();
    },
    selectedClient: function () {
      // this.loadMainContactPerson();
    }
  },
  mounted() {
    // this.loadMainContactPerson();
  },
  methods: {
    loadMainContactPerson() {
      if (!ApiService.getHeader("x-api-key")) {
        return;
      }

      Users.getMainContact()
        .then(response => {
          this.contactPerson = response.data.data;
          const profilePicture = this.contactPerson.profile_picture;
          if (profilePicture && !this.imageError) {
            this.contactPerson.profile_picture = profilePicture;
            return;
          }
          this.contactPerson.profile_picture = "/media/users/blank.png";
        })
        .catch(() => {
          this.contactPerson = {
            profile_picture: "",
            name: "",
            phone: "",
            email: ""
          };
        });
    }
  }
};
</script>
